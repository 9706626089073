import styled from "styled-components";

const BaseButton = styled.button`
  cursor: pointer;
  padding: 1.5rem 2rem;
  text-transform: uppercase;
  background-color: transparent;

  @media (max-width: 720px) {
    padding: 1rem 1.5rem;
  }
`

export const ButtonSquare = styled(BaseButton)`
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.color};
  color: currentColor;
  transition: all .2s;
  
  :hover {
    background-color: #f0f9ff;
  }
`
export const ButtonFlat = styled(BaseButton)`
  padding: 1.5rem 2rem;
  transition: all .2s;
  border: none;

  :hover {
    color: ${props => props.color};
  }
`