import React from 'react';

import { FooterColumnModels, FooterLink, FooterColumnTitle } from './styles';

const FooterModels = ({ title, models, handleClick }) => (
  <FooterColumnModels>
    <FooterColumnTitle>{ title }</FooterColumnTitle>
    <ul>
      {models.map(({title}) => (
        <li key={title}>
          <FooterLink
            to={`/parts`}
            onClick={(e) => handleClick(e, title) }
        >Автозачасти для {title}</FooterLink>
        </li>
      ))}
    </ul>
  </FooterColumnModels>
)

export default FooterModels
