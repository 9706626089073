import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";

import { ButtonSquare, ButtonFlat } from "./styles";

import { clearModalData, toggleModal } from "../../../../state/app";
import FormHandler from "../../../../features/Email/FormHandler";

const formHandler = new FormHandler();

const StyledModal = styled.div`
  display: flex;
  border: 1px solid #212121;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  width: calc(1344px - 10%);
  border-radius: 8px;
  overflow: hidden;
  @media (max-width: 1280px) {
    width: 95vw;
    max-height: 90vh;
  }
`;

const FormContainer = styled.div`
  flex: 1;
  padding: 3rem;
  background-color: #fafafa;

  .action {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    & > *:first-child {
      margin-right: 1rem;
    }
  }
  @media (max-width: 720px) {
    padding: 1rem 2rem;
    h2 {
      font-size: 1.8rem;
    }
  }
`;

const StyledDescription = styled.div`
  flex: 0 0 30%;
  background: #212d5e;
  color: #fafafa;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 720px) {
    display: none;
  }

  h1 {
    letter-spacing: 2px;
    line-height: 34px;
    text-align: center;
  }

  .content {
    text-align: center;
  }

  .contacts {
    align-self: flex-end;
  }
`;

const messages = {
    main: 'Для уточнения наличия и цены, для получения информации и совершения покупки, просьба связаться с нами по телефону или заполнить форму заявки',
    name: 'Нам важно знать как к вам обращаться',
    phone: 'Если предпочитатете, чтобы мы не беспокоили вас звонками, оставьте поле пустым',
    email: 'Нам нужно знать, куда направить информацию',
    message: 'Любые дополнительные сведения о вашем автомобиле (год выпуска, модификация, VIN и т.д.) помогут нам подобрать оптимальный вариант деталей',
}

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  padding: 1rem 0;

  label {
    margin-bottom: .5rem;
    &:not(:first-child) {
      margin-top: .5rem;
    }
  }

  input,
  textarea {
    padding: 1rem 1.5rem;
    border-width: 1px;
    border-style: solid;
    border-color: #212121;
    @media (max-width: 720px) {
      padding: .8rem 1.2rem;
    }
  }

  input:focus,
  textarea:focus {
    outline: #212d5e solid 2px;
  }
`;

const OrderModalCard = ({ modalData, dispatch }) => {
  const [activeMessage, setActiveMessage] = React.useState('main');
  const [name, setName] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [message, setMessage] = React.useState('');

  const handleSubmit = async event => {
    event.preventDefault();
    const formData = {
      subject: "Заявка на подбор запчастей",
      username: name,
      contact: `Телефон: ${phone}, email: ${email}`,
      model: modalData.model || '-',
      message: `Наименование ${modalData.itemName}, артикул ${modalData.code}. Дополнительная информация: ${message}`,
    };

    const { success } = await formHandler.postFormData(formData);
    if (success) {
      dispatch(toggleModal());
      dispatch(clearModalData());
      alert('Заявка успешнол отправлена');
    }

  }

  return (
    <StyledModal>
      <StyledDescription>
        <h1>Оставить заявку</h1>
        <div className="content">
          {messages[activeMessage]}
        </div>
        <div className="contacts">
          info@landcruiserparts.ru
        </div>
      </StyledDescription>
      <FormContainer>
        <h2>Заявка на подбор запчастей</h2>
        <div className="service">
          { `${modalData.itemName} (${modalData.model})` || 'Заявка на оформление документов' }
        </div>
        <StyledForm borderColor="#212121" onSubmit={ handleSubmit } autoComplete="off">
          <label htmlFor="name">Имя *</label>
          <input
            type="text"
            name="name"
            id="name"
            placeholder="Имя"
            onFocus={() => setActiveMessage('name')}
            onBlur={() => setActiveMessage('main')}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <label htmlFor="phone">Номер телефона</label>
          <input
            type="text"
            name="phone"
            id="phone"
            placeholder="+7 903 111-22-33"
            onFocus={() => setActiveMessage('phone')}
            onBlur={() => setActiveMessage('main')}
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <label htmlFor="email">Электронная почта *</label>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="email@mail.ru"
            onFocus={() => setActiveMessage('email')}
            onBlur={() => setActiveMessage('main')}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <label htmlFor="message">Сообщение</label>
          <textarea
            name="message"
            id="message"
            rows={5}
            placeholder="Дополнительные сведения"
            onFocus={() => setActiveMessage('message')}
            onBlur={() => setActiveMessage('main')}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <small>* отмечены обязательные поля</small>
          <div className="action">
            <ButtonFlat
              color="#F44336"
              onClick={(event) => {
                event.preventDefault();
                dispatch(toggleModal());
                dispatch(clearModalData());
              }}
            >Отмена</ButtonFlat>
            <ButtonSquare
              color="#212d5e"
              type="submit"
            >Отправить</ButtonSquare>
          </div>
        </StyledForm>
      </FormContainer>
    </StyledModal>
  )
}

export default connect(state => ({
  modalData: state.app.modalData,
}), null)(OrderModalCard)
