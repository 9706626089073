// @ts-ignore
import Api from "../Api/index";
import TemplateFactory from "./TemplateFactory";
import { EmailTemplateProps } from './EmailTemplate'

export interface FormData extends EmailTemplateProps {
    subject: string
}

export default class FormHandler {
    api: Api
    templateFactory: TemplateFactory

    constructor() {
        this.api = new Api();
        this.templateFactory = new TemplateFactory();
    }

    async postFormData({subject, ...data}: FormData) {

        const response = await this.api.axios.post("/", {
            "toEmails": [process.env.EMAIL_RECIPIENT],
            "subject": subject,
            "message": this.templateFactory.create(data)
        });
        const success = response.status === 204;
        return { success };
    }
}