import React from "react";
import styled from "styled-components";

const StyledButton = styled.a`
  display: inline-flex;
  align-items: center;
  margin-bottom: .5rem;
  position: relative;

  :after {
    display: block;
    content: '';
    width: 100%;
    height: 40%;
    position: absolute;
    top: 55%;
    left: 0;
    background-color: #C2E9FF;
    z-index: -1;
  }
`;

const SimpleButton = ({ children, onClick }) => (
  <StyledButton href="#" onClick={ onClick } >
    { children }
  </StyledButton>
);

export default SimpleButton;