import React from "react";
import styled from "styled-components";

import IconClose from "../../assets/icons/close-button.svg";

const StyledNavbar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateX(200vw);

  color: #fafafa;
  
  .background {
    height: 6rem;
    width: 6rem;
    position: fixed;
    top: 6.5rem;
    left: 6.5rem;
    background-image: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
    border-radius: 50%;
    z-index: 1020;
    transition: all .8s cubic-bezier(0.86, 0, 0.07, 1);
    transform: scale(0);
  }

  &.displayed {
    transform: translateX(0);
    .background {
      transform: scale(80);
    }
    ul {
      transform: translateX(0);
    }
    svg {
      transform: translate(-100%, 100%);
    }
  }

  ul {
    padding: 0;
    z-index: 1050;
    text-align: center;
    transform: translateX(-200%);
    transition: transform .4s ease-in;
    transition-delay: .3s;
    font-size: 1.8rem;
    font-weight: 600;
    li:not(:last-child) {
        margin-bottom: 1.8rem;
      }
  }

  svg {
    z-index: 1050;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, -200%);
    transition: transform .3s ease-in;
    transition-delay: .3s;

  }
`;

const MobileNavbar = ({display, toggleMenu, children}) => {

  return (
    <StyledNavbar className={ display ? 'displayed' : '' }>
      <IconClose height="20" width="20" fill="#fafafa" onClick={() => toggleMenu()}/>
      <div className="background" />
      <ul>{ children }</ul>
    </StyledNavbar>
  );
};

export default MobileNavbar;
