import React from 'react';

import { FooterColumnCopyright } from './styles';

const FooterCopyright = () => (
  <FooterColumnCopyright>
    &copy; 2019 Все права защищены
  </FooterColumnCopyright>
)

export default FooterCopyright
