import React from 'react';

import { FooterColumnServices, FooterLink, FooterColumnTitle } from './styles';

const FooterServices = ({ title, services }) => (
  <FooterColumnServices>
    <FooterColumnTitle>{ title }</FooterColumnTitle>
    <ul>
      <li>
       <FooterLink to="/parts">Запчасти для автомобилей Land Cruiser</FooterLink>
      </li>
      {services.map(service => (
        <li key={service.frontmatter.slug}>
          <FooterLink to={`/${service.frontmatter.slug}`}>{ service.frontmatter.title }</FooterLink>
        </li>
      ))}
    </ul>
  </FooterColumnServices>
)

export default FooterServices
