import React from 'react';

import { FooterColumn } from "./styles";

const FooterContacts = ({ address, phone, email }) => (
  <FooterColumn>
    <ul>
      <li>{address}</li>
      <li>{phone}</li>
      <li><a href={`mailto:${email}`}>{email}</a></li>
    </ul>
  </FooterColumn>
)

export default FooterContacts
