import StringNormalizer from "../../Base/StringNormalizer";

export default class BreadcrumbNormalizer extends StringNormalizer {

  normalizeTitle(title, length = 35) {
    if (title.length <= length) return title;
    const normalizedLength = this.trimToNChars(title, length);
    const normalizedTitle = this.normalizeCharsCase(normalizedLength);
    return normalizedTitle;
  }
}