import styled from 'styled-components';
import { Link } from 'gatsby';
import { Container } from '../../styles/Container';

export const StyledFooter = styled.footer`
  background-color: #100e17;
  padding-top: 4rem;
  color: #fafafa;
`;

export const FooterContainer = styled(Container)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(36rem, 1fr));
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;

  @media (max-width: 1180px) {
    grid-template-columns: 1fr;
  }
`;

export const FooterColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FooterColumnTitle = styled.div`
  margin-bottom: 1.5rem;
  font-weight: 600;
  font-size: 1.8rem;
`;

export const FooterLink = styled(Link)`
  &:hover {
    text-decoration: underline;
  }
`;

export const FooterColumnServices = styled(FooterColumn)`
  @media (min-width: 1180px) {
    justify-self: center;
  }
`;

export const FooterColumnModels = styled(FooterColumn)`
  @media (min-width: 1180px) {
    justify-self: end;
  }
`;

export const FooterColumnCopyright = styled(FooterColumn)`
  grid-column: 1 / -1;
  padding: 1rem 0;
`;