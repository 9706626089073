import React from "react";
import styled from "styled-components";
import { useMediaQuery } from 'react-responsive';

import Breadcrumbs from "./Breadcrumbs";
import SimpleButton from "../UI/Buttons/SimpleButton";
import IconBackward from "../../assets/icons/left-arrow.svg";

const StyledNavigation = styled.aside`
  padding: 1rem 0;
  margin-bottom: 1.5rem;
`;

const Navigation = ({ crumbs }) => {
  const handleClick = (e) => {
    e.preventDefault();
    window.history.back();
  };

  const isNotMobile = useMediaQuery({ query: '(min-width: 640px)' });

  return (
    <StyledNavigation className="container">
      <SimpleButton
        onClick={e => handleClick(e)}
      >
        <IconBackward
          width="12"
          height="12"
        />
        <span className="m-l-10">Вернуться назад</span>
      </SimpleButton>
      {isNotMobile && <Breadcrumbs crumbs={ crumbs } />}
    </StyledNavigation>
  );
}

export default Navigation;