export default class StringNormalizer {

  trimToNChars(string, chars) {
    const trimmedString = string.substring(0, chars - 3);
    return `${trimmedString}...`;
  }

  normalizeCharsCase(string) {
    const chars = string.split('');
    const normalizedChars = chars.map((char, i) => i === 0 ? char.toUpperCase() : char.toLowerCase());
    return normalizedChars.join('');
  }
}