/**
 * TODO: добавить отслкживание состояния хедера через redux
 */

import React from "react"

import { connect } from "react-redux";

import Header from "./Layout/Header";
import Footer from "./Layout/Footer";
import Navigation from "./Layout/Navigation";

import OrderModal from "./UI/Modals/OrderModal";

import "../assets/scss/main.scss"
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const Layout = ({ children, home, crumbs, showModal }) => {
  const navigationIsHidden = Boolean(home);
  return (
    <>
      <Header home={ home } />
      { navigationIsHidden ? '' : <Navigation crumbs={ crumbs } /> }
      <main style={{minHeight: '80vh', position: 'relative', overflow: 'hidden'}}>{children}</main>
      <Footer />
      { showModal && <OrderModal /> }
    </>
  );
}

// Layout.propTypes = {
//   children: PropTypes.node.isRequired,
// }

export default connect(state => ({
  showModal: state.app.showModal,
}), null)(Layout)
