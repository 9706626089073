import React from "react";

export interface EmailTemplateProps {
  username: string
  contact: string
  model: string
  message: string
}

const EmailTemplate: React.FC<EmailTemplateProps> = ({username, contact, model, message}) => (
  <div>
    <table style={{border: '1px solid #000'}}>
      <tr>
        <td>Имя</td>
        <td>{ username }</td>
      </tr>
      <tr>
        <td>Почта или телефон</td>
        <td>{ contact }</td>
      </tr>
      <tr>
        <td>Модель автомобиля</td>
        <td>{ model }</td>
      </tr>
      <tr>
        <td>Сообщение</td>
        <td>{ message }</td>
      </tr>
    </table>
  </div>
)

export {
  EmailTemplate
};