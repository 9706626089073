import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

const StyledBreadcrumbs = styled.ul`
  display: flex;

  li {
    display: flex;
    color: #3E4C59;

    &:before {
      content: '/';
      margin: 0 .8rem;
      color: currentColor;
    }

    .active {
      font-weight: 600;
      color: #2D3A8C;
    }
  }
`;

const Breadcrumbs = ({ crumbs }) => {
  return (
      <StyledBreadcrumbs>
        <li><Link to="/">Главная</Link></li>
        { crumbs.map(crumb => (
          <li key={crumb.path}>
            <Link
              to={ `/${crumb.path}` }
              activeClassName="active"
            >
              { crumb.title }
            </Link>
          </li>
        ))}
      </StyledBreadcrumbs>
  );
};

export default Breadcrumbs;