import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const LogoContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 1rem;
  }
  .content {
    line-height: normal;
    & > * {
      display: block;
      :first-child {
        font-weight: 600;
        font-size: 1.8rem;
        letter-spacing: 3px;
        text-transform: uppercase;
      }
    }
  }
`;

const HeaderLogo = () => (
  <Link to="/">
    <LogoContainer className="header__logo">
      {/* <IconJeep width="55" height="55" fill="#fff" /> */}
      <div className="content">
        <span>landcruiserparts.ru</span>
        <span>Все для LandCruiser</span>
      </div>
    </LogoContainer>
  </Link>
);

export default HeaderLogo;
