import React from "react";
import styled from "styled-components";

import HeaderLogo from "./HeaderLogo";
import HeaderNavigation from "./HeaderNavigation";

const StyledHeader = styled.header`
  /* height: 10vh; */
  height: 8vh;
  min-height: 6rem;
  width: 100%;
  box-shadow: 0px 1px 10px #999;

  &.home {
    height: 10vh;
    box-shadow: none;
    position: absolute;
    top: 0;
    left: 0;
    color: #fafafa;
    z-index: 100;
  }


  .container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const Header = ({ home }) => (
  <StyledHeader className={ home ? `home` : `` }>
    <div className="container">
      <HeaderLogo />
      <HeaderNavigation home={ home } />
    </div>
  </StyledHeader>
);

export default Header;