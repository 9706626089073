import React from 'react';

import { FooterColumn, FooterLink, FooterColumnTitle } from './styles';

const FooterInfo = ({ title }) => (
  <FooterColumn>
    <FooterColumnTitle>{ title }</FooterColumnTitle>
    <ul>
      <li>
       <FooterLink to="/">Главная</FooterLink>
      </li>
      <li>
        <FooterLink to="/novosti">Новости</FooterLink>
      </li>
      <li>
        <FooterLink to="/contacts">Контакты</FooterLink>
      </li>
    </ul>
  </FooterColumn>
)

export default FooterInfo
