import React from "react";
import styled from "styled-components";

import OrderModalCard from "./OrderModalCard";

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,.7);
`;

const OrderModal = () => {
  return (
    <ModalBackground>
      <OrderModalCard />
    </ModalBackground>
  )
}

export default OrderModal
