import React from "react";
import { graphql, useStaticQuery, navigate } from "gatsby";

import { connect } from "react-redux";
import { clearSearch, updateModelFilters } from "../../../state/app";

import { StyledFooter, FooterContainer } from './styles';

import FooterInfo from './FooterInfo';
import FooterServices from './FooterServices';
import FooterModels from './FooterModels';
import FooterContacts from './FooterContacts';
import FooterCopyright from './FooterCopyright'

const Footer = ({ dispatch }) => {
    const { services, config, contacts } = useStaticQuery(
      graphql`
        query {
          services:allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/services/"}}) {
            nodes {
              frontmatter {
                title
                slug
              }
            }
          }
          config:configYaml(type: {eq: "models"}) {
            models{
              title
            }
          }
          contacts:configYaml(type: {eq: "contacts"}) {
            adderss_details
            address
            email
            phone
            phone_1
            yandex_map_link
          }
        }
      `
    );
    const handleClick = (event, title) => {
      event.preventDefault();
      dispatch(clearSearch());
      dispatch(updateModelFilters([{
        type: 'model',
        value: title,
      }]));
      navigate('/parts')
    };

    return (
      <StyledFooter>
          <FooterContainer>
            <FooterInfo title="О нас" />
            <FooterServices
              title="Наши услуги"
              services={ services.nodes }
            />
            <FooterModels
              title="Продажа автозапчастей"
              models={ config.models }
              handleClick={ handleClick }
            />
            <FooterContacts
              address={contacts.address}
              phone={`${contacts.phone}, ${contacts.phone_1}`}
              email={contacts.email}
            />
            <FooterCopyright />
          </FooterContainer>
      </StyledFooter>
    )
  };
  
  export default connect()(Footer);