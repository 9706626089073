import BreadcrumbNormalizer from "./BreadcrumbNormalizer";
import Breadcrumb from "./BreadcrumbDTO";

export default class BreadcrumbsFactory {

  constructor() {
    this.normalizer = new BreadcrumbNormalizer();
  }

  fromArray(breadcrumbs) {

    const breadcrumbsDTO = [];
    breadcrumbs.forEach(({path, title}) => {
      const crumbDTO = new Breadcrumb(
        path,
        this.normalizer.normalizeTitle(title, 33),
      );
      breadcrumbsDTO.push(crumbDTO);
    });
    return breadcrumbsDTO;
  }

}