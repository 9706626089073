import axois from 'axios';

export default class Api {
  axios;
  constructor() {
    this.axios = axois.create({
      baseURL: process.env.EMAIL_SENDER_URL,
      headers: {
        'Content-Type': 'application/json',
        'Accept': '*/*',
      }
    });
  }
}