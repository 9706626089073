import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import IconMenu from "../../assets/icons/menu.svg";
import MobileNavbar from "./MobileNavbar";

const StyledNavigation = styled.nav`
  grid-column: 2 / 3;
`;

const DesktopNavigation = styled.ul`
  display: flex;
  font-size: 1.8rem;
  & > *:not(:last-child) {
    margin-right: 1.8rem;
  }
  a, a:visited, a:focus {
    color: currentColor;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
  @media (max-width: 1024px) {
    display: none;
  }
`;

const MobileNavigation = styled.span`
  @media not all and (max-width: 1024px) {
    display: none;
  }
`;

const NavbarLinks = () => (
  <>
    <li>
      <Link to="/">Главная</Link>
    </li>
    <li>
      <Link to="/parts">Каталог запчастей</Link>
    </li>
    <li>
      <Link to="/novosti">Новости</Link>
    </li>
    <li>
      <Link to="/info">Информация</Link>
    </li>
    <li>
      <Link to="/contacts">Контакты</Link>
    </li>
  </>
);

const HeaderNavigation = ({ home }) => {
  const [displayMenu, setDisplayMenu] = React.useState(false);
  const toggleMenu = () => {
    setDisplayMenu(!displayMenu);
  };
  return (
    <StyledNavigation>
      <DesktopNavigation>
        <NavbarLinks />
      </DesktopNavigation>
      <MobileNavigation>
        <IconMenu height="20" width="20" fill={ home ? `#fafafa` : `#000` } onClick={() => toggleMenu()} />
        <MobileNavbar display={ displayMenu } toggleMenu={ toggleMenu }>
          <NavbarLinks />
        </MobileNavbar>
      </MobileNavigation>
    </StyledNavigation>
  );
}

export default HeaderNavigation;